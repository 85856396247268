<template>
    <div class="d-flex flex-column flex-root">
        <div class="row m-0">
            <div class="p-0 m-0 col-lg-6">
                <section class="login_page">
                    <div class="login_page_inner">
                        <div class="brand-logo">
                            <a href="">
                                <img
                                    alt="Logo"
                                    class="logo"
                                    src="@/assets/images/logos/logo-yellow-white.png"
                                />
                            </a>
                        </div>
                        <div class="login_form_box">
                            <div class="login_page_heading">
                                <h1>Welcome.</h1>
                                <p>Please Login to Your Account</p>
                            </div>
                            <form @submit.prevent="submit">
                                <div class="form-box fv-row">
                                    <img
                                        src="@/assets/images/img/user_icon.png"
                                        class="iput-icon"
                                    />
                                    <input
                                        id="email"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                v$.user.email.$errors.length,
                                        }"
                                        placeholder="Email"
                                        type="email"
                                        v-model="v$.user.email.$model"
                                        autocomplete="off"
                                        autofocus
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="(error, index) of v$.user.email
                                            .$errors"
                                        :key="index"
                                    >
                                        <div
                                            class="error-msg"
                                            v-if="error.$validator == 'email'"
                                        >
                                            Please enter valid email
                                        </div>
                                        <div class="error-msg" v-else>
                                            Email address is required
                                        </div>
                                    </div>
                                </div>
                                <div class="form-box fv-row">
                                    <img
                                        src="@/assets/images/img/lock_icon.png"
                                        class="iput-icon"
                                    />
                                    <input
                                        id="password"
                                        type="password"
                                        v-model="v$.user.password.$model"
                                        :class="{
                                            'is-invalid':
                                                v$.user.password.$errors.length,
                                        }"
                                        placeholder="Password"
                                        class="form-control"
                                        autocomplete="off"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="(error, index) of v$.user
                                            .password.$errors"
                                        :key="index"
                                    >
                                        <div
                                            class="error-msg"
                                            v-if="
                                                error.$validator == 'required'
                                            "
                                        >
                                            Password is required
                                        </div>
                                    </div>
                                    <!-- <a href="" class="forgot-btn text-primary">
                                        Forgot?
                                    </a> -->
                                </div>
                                <div class="row d-flex">
                                    <!-- <div class="fs-7 col-5 mt-5">
                                        <label
                                            class="form-check form-check-custom form-check-solid"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                name="remember"
                                            />
                                            <span
                                                class="form-check-label fw-bold text-gray-700 fs-6"
                                                >Remember me
                                            </span>
                                        </label>
                                    </div> -->

                                    <div class="fs-7 col-12 mt-5  text-end">
                                        <RouterLink to="/forgot-password">
                                            <span class="fw-bolder text-primary">Forgot Password?</span>
                                        </RouterLink>
                                    </div>
                                </div>
                                <div class="btn-login-design d-flex">
                                    <button
                                        type="submit"
                                        class="btn btn-bg-primary text-white w-100"
                                        :disabled="loader"
                                    >
                                        <span v-if="!loader"> Continue </span>
                                        <span
                                            class="indicator-progress d-block"
                                            v-else
                                        >
                                            Please wait...
                                            <span
                                                class="spinner-border spinner-border-sm align-middle ms-2"
                                            ></span>
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <div class="col-lg-6 d-lg-block d-none p-0 m-0">
                <!-- <div id="sob-login-container"></div> -->
                <SobLoginScreen @showSoblogin="(val) => isShowSoblogin = val" />
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";
import SobLoginScreen from "@/components/Sob/LoginScreen.vue";

export default {
    name: "login-page",
    setup() {
       const toast = useToast();
        return {toast, v$: useVuelidate() };
    },
    components : {
        SobLoginScreen
    },
    data() {
        return {
            user: {
                email: "",
                password: "",
            },
            loader: false,
            isShowSoblogin : true,
        };
    },
    methods: {
        ...mapActions({
            login: "Auth/login",
        }),
        submit() {
             this.v$.user.$touch();
            if (this.v$.user.$invalid) {
                return;
            }
            this.loader=true
                this.login(this.user)
                .then((response) => {
                    console.log(response)
                    this.loader = false;
                    if (response.data.status == true) {
                    //    this.toast.success("Login Successfully");
                        this.$router.push({name: 'Home'})
                    }
                })
                .catch((error) => {
                        this.loader=false
                       this.toast.error(error.response.data.message);
                });
        },
    },
    validations() {
        return {
            user: {
                email: { required, email },
                password: { required },
            },
        };
    },
    mounted(){
        //   window.LoginScreenLibrary.init([
        //         "sob-login-container",
        //         "https://app.saasonboard.com/",
        //         "SvbrVZCfz9uEaZu",
        //         "login-iframe"
        //     ]);
    }
};
</script>
<style scoped>
.bg-body {
    background-color: #eeeff8 !important;
}
.is-invalid {
    border-color: #f1416c !important;
}
.error-msg {
    font-size: 12px;
    color: #f1416c;
    margin-top: 2px;
}
.login_page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #F5F5F5; */
    padding: 60px 0;
}
.eye-icon.eyehide i:before {
    content: "\f070";
}
.brand-logo {
    margin: 0 auto;
    margin-bottom: 27px;
    text-align: center;
}

.brand-logo a {
    display: inline-block;
    width: 100%;
}

.brand-logo img {
    width: 100%;
}

.login_form_box {
    background: #ffffff;
    width: 100%;
    border-radius: 12px;
    padding: 32px 33px 21px 33px;
    position: relative;
    box-shadow: 0px 8px 10px #0000000d;
}

.login_page_inner {
    margin: 0 20px;
    width: 472px;
}

.login_page_heading h1 {
    font-family: "Poppins", sans-serif;
    color: #4c4c4c;
    font-weight: bold;
    font-size: 27px;
    margin-bottom: 2px;
}
.btn-login-design {
    text-align: center;
    margin-top: 28px;
    max-width: 100%;
}
.login_page_heading p {
    margin: 0 0 21px 0;
    color: #8e8e8e;
    font-size: 14px;
}
.forgot-btn {
    position: absolute;
    right: 20px;
    top: 50%;
    cursor: pointer;
    font-weight: bold;
    transform: translateY(-50%);
}
.form-box input {
    width: 100%;
    outline: none;
    border: 1px solid #e3e3e3;
    background: transparent;
    padding: 18px 30px 18px 42px;
    height: auto !important;
    font-size: 14px;
    color: #696974;
    border-radius: 5px;
}

.form-box img {
    width: 15px;
    position: absolute;
    top: 20px;
    left: 16px;
}

.form-box {
    position: relative;
}

.form-box + .form-box {
    margin-top: 11px;
}

.reset-password-text {
    margin: 43px 0 0 0;
    text-align: center;
    color: #919191;
}
.login_page_heading p.alert.alert-danger {
    margin: -40px 0 20px 0;
}
.reset-password-text a {
    color: #412fc9 !important;
    margin-left: 8px;
    font-weight: bold;
}

.login_form_box_main {
}

.btn-login-right {
    position: absolute;
    bottom: -44px;
    width: 86px;
    height: 86px;
    background: #412fc9;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100%;
    overflow: hidden;
}
.btn-login-right input {
    width: 100%;
    height: 100% !important;
    opacity: 0;
}

.btn-login-right i {
    position: absolute;
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
    left: 50%;
    font-size: 40px;
}
.eye-icon {
    position: absolute;
    right: 0;
    top: 0;
    color: #696974;
    cursor: pointer;
}
.sign-up-link {
    margin: 0 0 0 0;
    color: #989898;
    font-size: 12px;
}

.sign-up-link a {
    color: #717171;
    font-weight: bold;
}
.login-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.login-options label {
    margin: 0;
    display: block;
    position: relative;
    padding-left: 21px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 12px;
    color: #717171;
}

.login-options label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.login-options label .login-checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: transparent;
    border: 1px solid #e3e3e3;
    transform: translateY(-50%);
    border-radius: 2px;
}
@media only screen and (max-width: 991px) {
    iframe#SaasOnBoardIFrame_15 {
        height: 100vh;
    }
    .bg-white .col-lg-6:last-child {
        display: none;
    }
}
@media only screen and (max-width: 767px) {
    .login_form_box {
        padding: 40px 30px 60px 30px;
    }

    .login_page_heading h1 {
        font-size: 30px;
    }

    .login_page_heading p {
        margin-bottom: 40px;
    }

    .form-box + .form-box {
        margin-top: 40px;
    }
}
.logo {
    width: 175px !important;
    margin-bottom: 25px;
}
</style>
